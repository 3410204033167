<script>
import { request, alert } from "@/common/mixins/mix_helper";
import AppConfig from "@/common/config/app.config.json";

export default {
  name: "Tags",
  mixins: [request, alert],
  props: {
    threadid: {
      required: true,
    },
  },
  data() {
    return {
      model: {
        tag: null,
      },
      tags: [],
      tagsMaster: [],
      tagsResult: [],
    };
  },
  methods: {
    onFindTags() {
      this.tagsResult = [];
      if (this.model.tag.length >= 2) {
        this.tagsResult = this.tagsMaster.filter((e) =>
          e.name.includes(this.model.tag)
        );
      }
    },
    onChooseResult(e) {
      this.model.tag = e.name;
      this.tagsResult = [];
    },
    onAdd() {
      this.API.post(this.URL.tags.create, {
        tag: this.model.tag,
        id: this.threadid,
      })
        .then(() => {
          this.fetchTags();
          this.mixToast(`Tag <b>${this.model.tag}</b> added successfully.`, {
            style: {
              background: "var(--success)",
            },
          });
          this.model.tag = null;
        })
        .catch(() => {
          this.mixToast(`Tag <b>${this.model.tag}</b> remove failed.`, {
            style: {
              background: "var(--danger)",
            },
          });
        });
    },
    onRemove(e) {
      this.API.post(this.URL.tags.remove, {
        tagid: e.id,
      })
        .then(() => {
          this.fetchTags();
          this.mixToast(`Tag <b>${e.name}</b> remove successfully.`);
        })
        .catch(() => {
          this.mixToast(`Tag <b>${e.name}</b> remove failed.`, {
            style: {
              background: "var(--danger)",
            },
          });
        });
    },
    fetchTags() {
      this.API.get(this.URL.tags.list, `?id=${this.threadid}`)
        .then(({ data }) => {
          this.tags = data;
        })
        .catch((err) => {
          console.warn("Tags fetch failed ", err);
        });
    },
    fetchTagMaster() {
      this.API.get(this.URL.tags.master)
        .then(({ data }) => {
          this.tagsMaster = data;
        })
        .catch(() => {
          this.tagsMaster = [];
        });
    },
  },
  computed: {
    ModuleOn() {
      return AppConfig.modules.includes("Tags");
    },
  },
  mounted() {
    this.fetchTags();
    this.fetchTagMaster();
  },
  watch: {
    threadid() {
      this.fetchTags();
    },
  },
};
</script>

<template>
  <div class="px-3 py-0 w-100" v-if="ModuleOn">
    <form @submit.stop.prevent="onAdd" class="p-0">
      <label class="font-weight-bold">Tags</label>
      <div class="input-area position-relative">
        <input
          type="text"
          placeholder="Enter Tag"
          v-model="model.tag"
          @keyup="onFindTags"
        />
        <ul
          class="position-absolute bg-light w-100 mt-1 rounded"
          id="searchResult"
          v-if="tagsResult.length > 0"
        >
          <li
            class="curp px-2 pt-1"
            v-for="tag in tagsResult"
            :key="tag.id"
            @click="onChooseResult(tag)"
          >
            {{ tag.name }}
          </li>
        </ul>
      </div>
    </form>

    <div class="mt-2 d-flex w-100 flex-wrap gap-2" id="tags">
      <div class="badge badge-info mx-0" v-for="i in tags" :key="i.id">
        {{ i.name }} (<span class="curp text-danger" @click="onRemove(i)"
          >x</span
        >)
      </div>
    </div>
  </div>
</template>

<style scoped>
div#tags {
  gap: 0.1em;
}
div.badge {
  min-width: auto;
  width: auto;
}
.curp {
  cursor: pointer;
}
ul#searchResult {
  min-height: 1em;
  max-height: 10em;
  overflow: scroll;
}
ul#searchResult > li:hover {
  background: var(--cyan);
  color: var(--white);
}
</style>
